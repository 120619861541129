import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Inbox from "./pages/Inbox";
import Upload from "./pages/Upload";
import Friends from "./pages/Friends";
import BottomNav from "./components/BottomNav";
import VideoFeed from "./components/VideoFeed";
import VideoPlayer from "./components/VideoPlayer";
import CommentSection from "./components/CommentSection";
import LikeButton from "./components/LikeButton";
import ShareButton from "./components/ShareButton";
import BookmarkButton from "./components/BookmarkButton";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/friends" element={<Friends />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
