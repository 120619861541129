import React, { useState, useEffect, useRef } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import "./Home.css";

const Home = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const containerRef = useRef(null);

  // Email validation function
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Reset errors
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Enter a valid email.";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post("http://localhost:5000/login", formData);
      console.log("Login Success", response.data);
    } catch (err) {
      console.log(err);
      setError("Invalid credentials");
    }
  };

  // Google Login Success
  const handleLoginSuccess = async (response) => {
    const res = await axios.post("http://localhost:5000/google-login", { tokenId: response.tokenId });
    console.log("Login Success:", response);
  };

  // Google Login Failure
  const handleLoginFailure = (error) => {
    console.error("Login Failed:", error);
  };


  return (
    <div className="container" tabIndex="0" ref={containerRef}>
      <center>
        <img
          border="0"
          title="mobilsocial local social media app"
          alt="mobilsocial local social media app"
          src="https://www.mobilsocial.com/mobilsocial-social-media-app-logo.png"
        />
      </center>

      <h1>Login</h1>

      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <input
          type="text"
          name="email"
          placeholder="Username or Email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="error-text">{errors.email}</p>}

        {/* Password Input */}
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
        />
        {errors.password && <p className="error-text">{errors.password}</p>}

        {/* Submit Button */}
        <button type="submit">Sign In</button>
      </form>

      {error && <p className="error-text">{error}</p>}

      <center>
        <br />
        <GoogleOAuthProvider clientId="434048507758-smj3cl98mhpg4295u847ek72mhigt489.apps.googleusercontent.com">
          <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
        </GoogleOAuthProvider>
      </center>

      <div className="footer-text">
        <p>
          Don't have an account? <span>Sign up</span>
        </p>
        <p>Forgot password?</p>
      </div>
    </div>
  );
};

export default Home;
